import React from "react";
import { SectionContent } from "../../common/SectionContent";
import { SectionHeader } from "../../common/SectionHeader";
import { ImageContainer } from "../../common/ImageContainer";

export const BillingRevenues = () => {
  return (
    <React.Fragment>
      <SectionHeader title='BILLINGS & REVENUES' id='billingRevenues'>
        <SectionContent>
          <p>
            The Billings & Revenues reports offer a comprehensive perspective on
            both billings and recognized revenues, providing a total overview
            and a breakdown by product.
          </p>
          <ImageContainer
            fileName='billingRevenues.png'
            alt='Billings Revenues'
          />
          <b>All Products/Services:</b>
          <p>
            Access a consolidated report presenting a detailed breakdown of
            revenue, including revenue from new customers, renewals, and more,
            for all products and services. This report provides a holistic view
            of your overall revenue landscape.
          </p>
          <b>By product/service</b>
          <p>
            Gain insights into the revenue landscape with a product-wise
            breakdown report. This detailed analysis includes revenue from new
            customers, renewals, and other relevant metrics specific to each
            product or service, offering a granular view of your revenue
            streams.
          </p>
        </SectionContent>
      </SectionHeader>
    </React.Fragment>
  );
};
