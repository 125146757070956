class AppContextData {
  private dialogManager = null;

  constructor() {}

  getDialogManager() {
    return this.dialogManager;
  }
}

export default AppContextData;
