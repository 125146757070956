import { Typography } from "@mui/material";
import React from "react";
import { BulletList } from "../../common/BulletList";
import { SectionContent } from "../../common/SectionContent";
import { SectionHeader } from "../../common/SectionHeader";

export const Introduction = () => {
  return (
    <React.Fragment>
      <SectionHeader title='INTRODUCTION'>
        <SectionContent>
          {`Seqnc is a powerful financial modelling, planning, and analysis
        application. Use of the application is structured in three parts:`}
          <BulletList>
            {`The first step is to set up your model structure. This is analogous to
          setting up a skeleton spreadsheet with rows for various revenues,
          costs, income, and capital line items. The model setup process is
          designed as an intuitive step by step process that covers your revenue
          sources (Products & Services), cost items (Costs & Expenses),
          headcount/workforce structure (Departments & Roles), and capital
          expenditure items (Fixed Assets).`}
          </BulletList>
          <BulletList>
            {`The next step is to provide assumptions. This is done in our application through:`}
            <BulletList>
              {`A spreadsheet pre-populated with the rows that correspond to your model setup.`}
            </BulletList>
            <BulletList>{` Forms with dropdown and input fields for assumptions that apply across the model.`}</BulletList>
          </BulletList>
          <BulletList>
            {`Finally, you can view and download reports from a comprehensive set of financial and non-financial reports. These reports include: financial statements, subscription metrics, and operating metrics. These reports provide deep insight into your forecasted performance.`}
          </BulletList>
          {`The application is highly flexible. You can change an aspect of model setup and assumptions in any order without impacting computations. The software supports the creation of alternate scenarios to evaluate changes in financial performance and capital requirements. The familiar spreadsheet input feature supports user-defined formulas to build driver-based assumptions in your model. These features are designed to provide insights into how different assumptions impact your company. Users can invite other users to create, modify, or view a model. A comprehensive set of reports for financial and operating metrics make it easy to view and share key information in Microsoft Excel format.`}
          <br />
          <Typography
            style={{ marginTop: "20px" }}
          >{`Get started!`}</Typography>
        </SectionContent>
      </SectionHeader>
    </React.Fragment>
  );
};
