import React from "react";
import { SectionContent } from "../../common/SectionContent";
import { SectionHeader } from "../../common/SectionHeader";
import { ImageContainer } from "../../common/ImageContainer";

export const CreateModel = () => {
  return (
    <React.Fragment>
      <SectionHeader title='CREATE MODEL'>
        <SectionContent>
          <ImageContainer fileName='createModel.png' alt='Create Model' />
          {`Model Setup is the section where you set up the basic model outline for your forecast. You will define all your products and services, line items costs and expenses, line items for fixed assets (capital expenditure), and your headcount/workforce structure. Model setup is analogous to setting up a skeleton spreadsheet structure (based on which you will add your assumptions in the form of data for each line item). You can change your model setup at any time. Any changes will be reflected automatically in the model structure so that you will be able to add assumptions based on the changes.`}
        </SectionContent>
      </SectionHeader>
    </React.Fragment>
  );
};
