import React from "react";
import { SubSectionHeader } from "../../../common/SubSectionHeader";
import { SectionContent } from "../../../common/SectionContent";

export const BusinessModel = () => {
  return (
    <React.Fragment>
      <SubSectionHeader title='BUSINESS MODEL'>
        <SectionContent>
          <table>
            <tr>
              <td>{`Business model`}</td>
              <td>{`Your product’s business model is a a description of you offer it to your customers. Select from one of these options for each product you offer – they can all be different – and you can change your inputs at any time. Our model will use your selection and inputs in revenue-related calculations.
                        (i)     Select “Subscription/SaaS(recurring revenue)” if you offer this product as a recurring revenue, renewal subscription;
                        (ii)    Select “Product sale/transaction (one-time, non recurring revenue)” if the product is a traditional one-time sale product with no recurring charges;
                        (iii)  Select “Service/consulting contract (delivered over a period, non recurring revenue)” if this is a contract delivered over a period of months but is one-time and not recurring`}</td>
            </tr>
            <tr>
              <td>{`Subscription term`}</td>
              <td>{`For a recurring revenue product, this is the term for which the customer is purchasing your product.`}</td>
            </tr>
            <tr>
              <td>{`Billing frequency or prepayment term`}</td>
              <td>{`For a recurring revenue product, this is the term for which the customer is prepaying or paying upfront. Select month to month if that’s how your customers pay for this product.`}</td>
            </tr>
            <tr>
              <td>{`Advance payment`}</td>
              <td>{`This option is applicable for a non-recurring, “one-time sale” product. You can choose whether you expect a customer to pay an portion of the invoice amount at at the time of purchase (rather that upon delivery, which may or may not be at the time of purchase). You can select either option: (i) “Customers don’t pay an advance amount with their purchase”; or (ii) Customers pay _  % of invoice as advance with their purchase`}</td>
            </tr>
            <tr>
              <td>{`Term of contract`}</td>
              <td>{`Specify the number of months over which this contract is expected to be delivered. This field is enabled if you selected the business model “Service/consulting contract (delivered over a period, non recurring revenue)”.`}</td>
            </tr>
            <tr>
              <td>{`Advance payment, if any, at the time contract is signed`}</td>
              <td>{`Specify if customers prepay for any portion of the contract, expressed as number of months prepaid. This field is enabled if you selected the business model “Service/consulting contract (delivered over a period, non recurring revenue)”.`}</td>
            </tr>
          </table>
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
