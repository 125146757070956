import React from "react";
import { ImageContainer } from "../../common/ImageContainer";
import { SectionContent } from "../../common/SectionContent";
import { SectionHeader } from "../../common/SectionHeader";
import { SubSectionHeader } from "../../common/SubSectionHeader";

export const Onboard = () => {
  return (
    <React.Fragment>
      <SectionHeader title='ONBOARD'>
        <SectionContent style={{ marginBelow: "10px" }}>
          The onboarding flow is a crucial step for users to set up and
          configure their account after a successful signup or login. This
          documentation provides a step-by-step guide to the onboarding process,
          which includes setting up company information and configuring the
          forecast model.
          <br />
          <br />
          <b>Steps</b>
          <ol type='1'>
            <li>
              Redirect to Onboard Flow
              <ul>
                <li>
                  Upon successful signup or login, users will be automatically
                  redirected to the onboarding flow to initiate the setup
                  process.
                </li>
              </ul>
            </li>
            <li>Provide name for the company/account</li>
            <li>
              Select Forecast Model
              <ul>
                <li>
                  Users can choose to integrate their account with QuickBooks /
                  XERO / Zoho Books for real-time accounting data.
                </li>
                <li>
                  If no integration is selected, a default forecast model will
                  be created.
                </li>
              </ul>
            </li>
            <li>
              Configure Company and Scenario Information
              <ul>
                <li>
                  Users need to specify the end month of their company's fiscal
                  year.
                </li>
                <li>
                  Input a name for the scenario related to the forecast model.
                </li>
                <li>Choose the starting month for the forecast.</li>
                <li>Specify the number of timer periods for the scenario.</li>
              </ul>
            </li>
            <li>
              Upon completion of the onboarding flow, the user will be
              redirected to the dashboard, where they can start using the
              application with the configured settings.
            </li>
          </ol>
          The onboarding flow is designed to make the initial setup process
          seamless for users, allowing them to quickly configure their account
          and start using the application effectively.
          <ImageContainer fileName='onboard-1.png' alt='Onboard-1' />
          <br></br>
          <br></br>
          <ImageContainer fileName='onboard-2.png' alt='Onboard-2' />
          <br></br>
          <br></br>
          <ImageContainer fileName='onboard-3b.png' alt='Onboard-3' />
          <br></br>
          <br></br>
          <ImageContainer fileName='onboard-4.png' alt='Onboard-4' />
        </SectionContent>
      </SectionHeader>
    </React.Fragment>
  );
};
