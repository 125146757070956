import React from "react";
import { Footer } from "../components/common/Footer";
import { ContentPanel } from "./ContentPanel";
import { LeftPanel } from "./LeftPanel";
import { Bar } from "./AppBar";
import { Toolbar } from "@mui/material";
import { ViewPort } from "./ViewPort";

export const AppContainer = () => {
  const [toggleNavPanel, setToggleNavPanel] = React.useState(true);

  const toogleHandler = () => {
    setToggleNavPanel(!toggleNavPanel);
  };

  return (
    <React.Fragment>
      <Bar setToggleNavPanel={toogleHandler} />
      <Toolbar style={{ minHeight: "30px" }} />
      <ViewPort toggleNavigationPanel={toggleNavPanel}>
        <LeftPanel toggleNavPanel={toggleNavPanel} />
        <ContentPanel toggleNavPanel={toggleNavPanel} />
      </ViewPort>
      <Footer />
    </React.Fragment>
  );
};
