import React from "react";
import { SectionHeader } from "../../../common/SectionHeader";
import { SectionContent } from "../../../common/SectionContent";
import { SalesAndPricing } from "./SalesAndPricing";
import { Drivers } from "./Drivers";
import { WorkForce } from "./WorkForce";
import { Costs } from "./Costs";
import { FixedAssets } from "./FixedAssets";
import { BSAssumptions } from "./BSAssumptions";
import { OtherIncome } from "./OtherIncome";

export const InputSpreadSheet = () => {
  return (
    <React.Fragment>
      <SectionHeader title='INPUT SPREADSHEET'>
        <SectionContent>
          <p>The application offers two primary methods for inputting data:</p>
          <ol type='1'>
            <li>
              Spreadsheet Input:
              <ul>
                <li>
                  Ideal for data that varies by time period, the spreadsheet
                  input allows users to input dynamic information. Common
                  features such as formulas, drag across, and undo/redo are
                  supported. For reference, detailed instructions are available
                  in the 'Instructions' sheet.
                </li>
              </ul>
            </li>
            <li>
              Form Inputs:
              <ul>
                <li>
                  Forms are provided for assumptions that apply broadly,
                  offering a structured input method. This is particularly
                  useful for data that doesn't vary by time period.
                </li>
              </ul>
            </li>
          </ol>
          <p>
            Additionally, the integrated spreadsheet supports common features
            like formulas, drag across functionality, and undo/redo. The
            application further supports the import of custom worksheets,
            enabling users to link them to system sheets formulaically or retain
            them for informational purposes.
          </p>
        </SectionContent>
      </SectionHeader>
      <SalesAndPricing />
      <WorkForce />
      <Costs />
      <FixedAssets />
      <BSAssumptions />
      <OtherIncome />
      <Drivers />
    </React.Fragment>
  );
};
