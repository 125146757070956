import React from "react";
import PropTypes from "prop-types";

type Props = {
  lineItemName: string;
};

function BulkAddNote(props: Props) {
  const { lineItemName } = props;
  return (
    <p>
      You can add {lineItemName} in bulk as well. Click on &quot;Switch to bulk
      add form&quot;. This will take you to bulk add form where you can input
      names of {lineItemName} as either comma separated list or can import from
      a csv file.{" "}
    </p>
  );
}

export default BulkAddNote;
