import React, { useEffect } from "react";
import { SectionHeader } from "../../common/SectionHeader";
import { SectionContent } from "../../common/SectionContent";
import { BulletList } from "../../common/BulletList";
import { ImageContainer } from "../../common/ImageContainer";

export const F2A = () => {
  return (
    <React.Fragment>
      <SectionHeader title="FORECAST TO ACTUAL" id="forecastToActual">
        <SectionContent>
          {`This Section is used to compare predicted or projected financial figures with the actual results. 
            It involves comparing the forecasts or estimates made in a financial model with the real-time financial data.`}
          <ol type="1">
            <BulletList>
              {`After forecast model and input is provided the forecasted values will be compared 
                with the actual values based on the line item / account mappings. In this sections metrics related P&L and Balances Sheet reports will be projected`}
            </BulletList>
            <BulletList>
              {`Comparison: The forecasted figures from the financial model are then compared to the 
                actual results. This involves assessing the variances or differences between the projected and actual financial performance.`}
            </BulletList>
          </ol>
          {`The forecast to actual comparison enables organizations to evaluate the accuracy of their 
            projections, make informed decisions based on real-time data, and improve the reliability of
            their future forecasts. It provides valuable insights into the financial performance of a company and helps identify areas for improvement or potential risks.`}
          <ImageContainer
            fileName="Forecast_to_actual.png"
            alt="Forecast to Actual"
          />
          <p id="breakup-info">
            <strong>Viewing breakup of actuals:</strong> You can view the
            breakup of the amount displayed against the actuals by clicking on
            "Details" link displayed in the cell. This link will not appear if
            there are no values for a given cell. The details will be displayed
            in a popup.
          </p>
          <ImageContainer fileName="Actual_breakup.png" alt="Actuals breakup" />
          <p>{`Wherever category view is supported , if user has mapped to only consolidated line then the report will fallback to line item view irrespective of the view settings `}</p>
        </SectionContent>
      </SectionHeader>
    </React.Fragment>
  );
};
