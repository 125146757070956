import React from "react";
import { SubSectionHeader } from "../../../../common/SubSectionHeader";
import { SectionContent } from "../../../../common/SectionContent";
import { ImageContainer } from "../../../../common/ImageContainer";

export const Equity = () => {
  return (
    <React.Fragment>
      <SubSectionHeader title='Equity' id='equity'>
        <SectionContent>
          <p>
            Effectively managing equity is vital for comprehensive financial
            modeling, and Seqnc provides a powerful platform to handle various
            aspects of equity. This guide focuses on managing equity with the
            added flexibility of specifying par value. Incorporate a planned
            equity round by specifying the amount you intend to raise and the
            month in which you plan to initiate the fundraising.
          </p>
          <ImageContainer fileName='addEquity.png' alt='Add Equity' />
          <b>Adding New Equity:</b>
          <ol>
            <li>
              Navigate to the 'Equity' section under 'Capital plan' in the
              application.
            </li>
            <li>Click on the 'Add Equity' button to initiate the process.</li>
            <li>
              Fill in the required details for the equity item, including the
              amount raised, issuance date, and par value if applicable.
            </li>
            <li>
              Specify whether the equity has a par value by selecting 'Yes' or
              'No' in the provided option.
            </li>
          </ol>
          If the equity has a par value, provide the specific amount. This par
          value represents the nominal or face value assigned to all shares.
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
