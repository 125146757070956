import React from "react";

type Props = {
  toggleNavigationPanel: boolean;
  children: any;
};

export const ViewPort = (props: Props) => {
  return (
    <>
      <div className='sq-wrapper container-fluid'>
        <div
          className={
            props.toggleNavigationPanel ? "sq-container" : "sq-container-full"
            // "sq-container"
          }
        >
          {props.children}

          <div
            className='sq-nav-panel-overlay'
            style={{ display: props.toggleNavigationPanel ? "" : "none" }}
          />
        </div>
      </div>
    </>
  );
};
