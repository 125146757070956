import React from "react";
import { SectionContent } from "../../../common/SectionContent";
import { SectionHeader } from "../../../common/SectionHeader";
import { Department } from "./Department";
import { JobRoles } from "./JobRoles";

export const JobRolesDepartments = () => {
  return (
    <React.Fragment>
      <SectionHeader title='JOBS/ROLES & DEPARTMENTS'>
        <SectionContent>
          <p>
            Use this section to review/add/edit/delete job/role and departments.
            A job/role is how you organize your workforce; you can add any
            number of job/roles to the model. Once you add a job/role, you can
            specify how may employees you plan to add (or remove) for each
            job/role, by month, during the forecast period. Note that you have
            the option to not add any job/roles and instead directly add salary
            & benefits as an operating expense.
          </p>
          <p>
            You can categorize employees by Department, optionally. If you
            classify by Department, and allocate other expenses by Department,
            you’ll have the option to view your P&L by Department rather than
            only on a line-item basis.
          </p>
        </SectionContent>
      </SectionHeader>
      <Department />
      <JobRoles />
    </React.Fragment>
  );
};
