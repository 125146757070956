import React from "react";
import { SectionHeader } from "../../common/SectionHeader";
import { SectionContent } from "../../common/SectionContent";
import { SubSectionHeader } from "../../common/SubSectionHeader";
import { ImageContainer } from "../../common/ImageContainer";

export const ConfigureDataSource = () => {
  return (
    <React.Fragment>
      <SectionHeader title="CONFIGURE DATA SOURCE">
        <SectionContent>
          <p>{`This is where you can access your information that is currently 
          stored elsewhere and import it into Seqnc. Use 'Connect to other applications'
          for integrating real time account with your Seqnc account.`}</p>
          <p>
            Ensure you have connected to at least one real-time accounting
            application before configuring data sources.
          </p>
        </SectionContent>
      </SectionHeader>

      <SubSectionHeader
        title="THIRD PARTY DATA SOURCES"
        id="thirdPartyDataSource"
      >
        <SectionContent>
          <ImageContainer
            fileName="thirdPartyDS.png"
            alt="Third party data source"
          />
          <p>
            Utilize this to configure the data source for your real-time
            accounting application.
          </p>
          <p>{`Use the ‘Authorize new datasource’ link to authorize a real time data source.`}</p>
        </SectionContent>
      </SubSectionHeader>
      <SubSectionHeader title="DATA SOURCE MAPPINGS" id="dataSourceMappings">
        <SectionContent>
          <ImageContainer
            fileName="dsMappings.png"
            alt="Third party data source"
          />
          <p>{`Forecast to actual reports are categorized into ‘P_L’ and ‘Balance Sheet’. 
              Select the data source for each these categories for mapping the line items from the respected data source.`}</p>
          <p>{`Apart from the real time data source choose the ‘Manual’ option which allows to enter the actual values in the Forecast to Actual reports. If not mapped default setting would be manual`}</p>
        </SectionContent>
      </SubSectionHeader>
      <SubSectionHeader title="CHANGE FIELD MAPPINGS" id="changeFieldMappings">
        <SectionContent>
          <ImageContainer
            fileName="changeFieldMappings.png"
            alt="Change field mappings"
          />
          <p>{`This feature will be enabled if the mapped data source for the report category is not ‘Manual’.`}</p>
          <p>{`In this section Seqnc accounts can be mapped to accounts retrieved from Third party data sources. Values from mapped accounts will be used for comparison.`}</p>
          <p>{`Please note that we don't allow mapping on both parent and child items at the same time.So You can either map to a parent item or map to children but not both `}</p>
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
