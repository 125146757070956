import React from "react";
import PropTypes from "prop-types";

type Props = {
  goToText: string;
  goToLink: string;
  lineItemName?: string;
  linkOnly?: boolean;
};
function GoToLink(props: Props) {
  const { goToText, goToLink, lineItemName, linkOnly = false } = props;
  const baseUrl = "https://app.seqnc.com";
  if (linkOnly) {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`${baseUrl}${goToLink}`}
      >
        {goToText}
      </a>
    );
  } else
    return (
      <span>
        Go to{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${baseUrl}${goToLink}`}
        >
          {goToText}
        </a>{" "}
        page to add a {lineItemName}
      </span>
    );
}

export default GoToLink;
