import React from "react";
import { SubSectionHeader } from "../../../common/SubSectionHeader";
import { SectionContent } from "../../../common/SectionContent";
import { ImageContainer } from "../../../common/ImageContainer";
import GoToLink from "../../../common/GoToLink";
import GoToLink1 from "../../../common/GoToLink1";

export const SalesAndPricing = () => {
  return (
    <React.Fragment>
      <SubSectionHeader title="SALES & PRICING">
        <SectionContent>
          <p>
            To input pricing and sales forecast assumptions for each product,
            navigate to the 'Pricing & Forecast' spreadsheet. Rows are
            dynamically generated based on user input in the 'Create Model'
            section, while the number of columns aligns with the forecast
            periods specified in the 'Forecast Description.
          </p>
          <p>
            It's important to note that any adjustments made to product details
            in the 'Model Setup' section will be automatically reflected in the
            'Pricing & Forecast' spreadsheet. This streamlined integration
            ensures that changes in the model setup seamlessly update the
            relevant sections, maintaining accuracy and coherence in the
            forecasting process.
          </p>
          <GoToLink1
            goToLink="/app/product-sheet"
            parentSection="Input assumptions/Input spreadsheet"
            goToText="Sales & Pricing"
          />
          <ImageContainer fileName="salesPricing.png" alt="Sales & Pricing" />
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
