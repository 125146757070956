import React from "react";
import { SectionContent } from "../../../common/SectionContent";
import { SectionHeader } from "../../../common/SectionHeader";
import { CopyLineItems } from "../JobRolesDepartments/CopyLineItems";
import GoToLink from "../../../common/GoToLink";

export const CostAndExpenses = () => {
  return (
    <React.Fragment>
      <SectionHeader title="COSTS & EXPENSES">
        <SectionContent>
          <p>
            This section of the Seqnc finance document allows you to
            meticulously review, add, edit, and delete direct costs and
            operating expenses. Seqnc provides the flexibility to specify these
            costs and expenses separately, each with distinct attributes. Any
            changes made to cost or expense details trigger an automatic
            re-computation of the entire model.
          </p>
          <p>
            Two Types of Costs
            <ol>
              <li>
                <i>Direct Costs: </i>Expenses directly associated with the
                production of goods or services.
              </li>
              <li>
                <i>Operating Expenses: </i>Costs related to the day-to-day
                operations of the business.
              </li>
            </ol>
            All expenses are organized by cost type and displayed in their
            respective tabs, ensuring a clear overview
          </p>
          <p>
            Manage expenses
            <ol>
              <li>
                Use the "Add Cost/Expense" button to seamlessly include new
                expenses in your financial model.
              </li>
              <li>
                Modify expense details by utilizing the edit icon associated
                with each expense in the table.
              </li>
            </ol>
          </p>
          <p>
            When adding details for each cost and expense line item, you will
            encounter the following input fields:
            <ol>
              <li>
                <i> Cost Description: </i>Provide a concise description of the
                cost.
              </li>
              <li>
                <i> Categorize This Cost As: </i>Categorize the cost based on
                predefined categories.
              </li>
              <li>
                <i> Expense Type: </i>Specify the type of expense or select "Not
                Applicable" if none applies. Seqnc automates calculations and
                adds applicable line items to your model for: (i)
                salaries/benefits/payroll taxes if you add jobs/roles; and (ii)
                depreciation if you add Fixed Assets
              </li>
              <li>
                <i> Is this COGS for a specific product?: </i>Specify the product 
                that is specific to the corresponding cost or select "Not Product 
                Specific" if none applies. <b>This does not apply to Operating Expenses.</b> 
                </li>
              <li>
                <i> Accounts Payable (A/P) Days: </i>Enter the number of days
                your suppliers give to pay their invoices
              </li>
              <li>
                <i> Inventory Applicable: </i>Indicate whether the cost is
                applicable to inventory. Select 'Yes' if this line item should
                drawdown from available inventory. Examples of this could
                include materials costs, parts costs, supply costs, and other
                costs for which you hold inventory. (NOTE: Add inventory by
                adding line item/s in 'Inventory purchases'.)
              </li>

              <li>
                <i> Do you prepay or recognize this cost over a period:</i>{" "}
                Select 'Yes' if you prepay this cost or would like to recognize
                it over a term. System will prompt for additional input
                accordingly. Applicable if "Expense Type" is "Not Applicable."
              </li>
              <li>
                <i>
                  Number of Months Prepaid or Over Which Cost Is Recognized:{" "}
                </i>
                Enter the number of months you prepay this cost or over which it
                should Applicable if "Prepay or Recognize Over a Period" is
                "No."
              </li>
              <li>
                <i> Percentage of Expense Deferred/Prepaid: </i> Applicable if
                "Prepay or Recognize Over a Period" is "No." Define the
                percentage.
              </li>
              <li>
                <i> Apply prior prepaid/deferred expense to this cost: </i>
                Select "Yes" if prepaid expense asset in your starting balance
                sheet should be applied toward this cost. A prepaid asset in the
                starting balance sheet , if any, will be drawndown (recognized)
                based on your selection. Determine whether prior
                prepaid/deferred expenses apply to this cost .
              </li>
            </ol>
          </p>
          <p>
            <GoToLink
              goToLink="/app/cost-and-expenses"
              goToText="Costs & Expenses"
              lineItemName="cost/expense"
            />
          </p>
          <p>
            Input Assumptions:
            <ol>
              <li>
                Navigate to the 'Input Spreadsheet' under the 'Input
                Assumptions' section in the left navigation menu.
              </li>
              <li>
                Provide input in the "Direct Costs" and "Operating Expenses"
                sheets under the "Input Spreadsheet."
              </li>
            </ol>
          </p>
          This structured approach ensures precise management of costs and
          expenses, promoting accurate financial modeling and forecasting.
        </SectionContent>
      </SectionHeader>
      <CopyLineItems />
    </React.Fragment>
  );
};
