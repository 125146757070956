import React from "react";
import { SectionContent } from "../../common/SectionContent";
import { SectionHeader } from "../../common/SectionHeader";
import { ImageContainer } from "../../common/ImageContainer";

export const FinancialProjections = () => {
  return (
    <React.Fragment>
      <SectionHeader title='Financial Projections' id='financialStatements'>
        <SectionContent>
          <p>
            Seqnc modeling utilizes advanced algorithms to compute and generate
            a comprehensive set of forecasts for financial statements,
            subscription metrics, and other operating metrics. The reports
            provide detailed insights into capital sources and uses, capital
            requirements, and cash flow. Export the projected financial
            statements seamlessly to Microsoft Excel for further analysis.
          </p>
          <ImageContainer
            fileName='financialProjections.png'
            alt='Financial Projection'
          />
          <b>Income Statement:</b>
          <p>
            Access projected income statements on a monthly basis throughout the
            forecast period. The view can be customized by line item or
            department based on your preferences in the model settings. Summary
            line items are expandable, allowing for a detailed display of line
            items and their corresponding values. Export the projected income
            statement to Microsoft Excel for enhanced data manipulation.
          </p>
          <b>Balance Sheet:</b>
          <p>
            Review the projected balance sheet on a monthly basis for the
            forecast period. Summary line items are expandable, offering a
            detailed breakdown of line items and their associated values. Export
            the projected balance sheet to Microsoft Excel for easy integration
            into your financial reporting processes.
          </p>
          <b>Cash Flow Statement:</b>
          <p>
            Examine the projected cash flow statement on a monthly basis for the
            forecast period. Summary line items are expandable, providing a
            detailed breakdown of individual items and their values. Export the
            projected cash flow statement to Microsoft Excel for seamless
            integration into your financial analysis toolkit.
          </p>
          <b>Ratios & Financial Metrics:</b>
          <p>
            Evaluate key financial metrics such as EBITDA, Free Cash Flow,
            Profitability Ratios, Coverage Ratios, Leverage Ratios, and
            Liquidity Ratios on a monthly basis throughout the forecast period.
            These metrics offer valuable insights into your financial
            performance. Export the projected metrics to Microsoft Excel for
            in-depth analysis and reporting.
          </p>
        </SectionContent>
      </SectionHeader>
    </React.Fragment>
  );
};
