import React, { useEffect, useState } from "react";
import { NavigationModule } from "../components/common/NavigationModule";
import { NavigationModuleChild } from "../components/common/NavigationModuleChild";
import { URLPart } from "../util/Path";
import { SimpleDialog } from "./SimpleDialog";
import { useSearchParams } from "react-router-dom";
import { NavigationMenu } from "./NavigationMenu";

type Props = {
  toggleNavPanel: boolean;
};

export const LeftPanel = (props: Props) => {
  const { toggleNavPanel } = props;
  const [currentLink, setCurrentLink] = useState(null);
  const [toggleDialogue, setToggleDialogue] = useState(false);
  const [message, setMessage] = useState<string>();
  const [title, setTitle] = useState<string>();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    console.log(searchParams);
    console.log(searchParams.get("sort"));
  }, []);

  useEffect(() => {
    let isSessionActive = false;
    const user = searchParams.get("user");

    const isUserActive = localStorage.getItem("isUserActive");
    if (isUserActive) {
      isSessionActive = isUserActive === "true";
    } else if (user && user.length > 0) {
      isSessionActive = true;
    }
    isSessionActive = true;
    if (!isSessionActive) {
      setTitle("App login required");
      setMessage("Login to the application");
      setToggleDialogue(true);
      localStorage.removeItem("isUserActive");
    } else {
      localStorage.setItem("isUserActive", "true");
    }
  }, []);

  return (
    <React.Fragment>
      <SimpleDialog
        title={title}
        message={message}
        open={toggleDialogue}
        onClose={() => {}}
      />
      <div
        className='sq-nav-panel'
        style={{ display: toggleNavPanel ? "" : "none" }}
      >
        <NavigationMenu
          currentLink={currentLink}
          setCurrentLink={setCurrentLink}
        />
      </div>
    </React.Fragment>
  );
};
