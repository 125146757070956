import React from "react";
import { SectionHeader } from "../../../../common/SectionHeader";
import { SectionContent } from "../../../../common/SectionContent";
import { ImageContainer } from "../../../../common/ImageContainer";
import { SubSectionHeader } from "../../../../common/SubSectionHeader";

export const Allocations = () => {
  return (
    <React.Fragment>
      <SectionHeader title='ALLOCATIONS'>
        <SectionContent>
          <p>
            Efficiently allocating direct costs and operating expenses by
            department is essential for gaining a department-wise view of the
            P&L/Income Statement in Seqnc. Additionally, you can specify how
            sales commissions' budgets should be allocated and, if desired,
            allocate costs of customer acquisition by product. These allocations
            serve as prerequisites for specific view options and calculations.
            The model provides default values where appropriate, but it's
            crucial to verify and confirm the allocations for your business.
          </p>
          <b>Steps to Allocate Costs:</b>
          <ol>
            <li>
              Navigate to Allocations Section:
              <ul>
                <li>
                  Access the 'Allocations' section in the Seqnc application.
                </li>
              </ul>
            </li>
            <li>
              Direct Costs Allocation:
              <ul>
                <li>
                  Allocate direct costs such as material costs, labor costs, and
                  other direct expenses to specific departments. This ensures a
                  granular view of how each department contributes to overall
                  costs.
                </li>
              </ul>
            </li>
            <li>
              Operating Expenses Allocation:
              <ul>
                <li>
                  Specify how operating expenses like rent, utilities, and
                  administrative costs are distributed among departments. This
                  step allows for a detailed breakdown of department-wise
                  expenditure.
                </li>
              </ul>
            </li>
            <li>
              Sales Commission Allocation:
              <ul>
                <li>
                  If applicable, allocate sales commission budgets among
                  departments. This provides insights into the performance of
                  each department in generating sales and managing associated
                  costs.
                </li>
              </ul>
            </li>
            <li>
              Cost of Customer Acquisition:
              <ul>
                <li>
                  Optionally, allocate costs of customer acquisition by product.
                  This step offers a nuanced understanding of the expenses
                  related to acquiring customers for each product.
                </li>
              </ul>
            </li>
            <li>
              Verification and Adjustment:
              <ul>
                <li>
                  Verify the default allocations provided by the model and
                  adjust them as needed to align with your business's specific
                  structure and requirements.
                </li>
              </ul>
            </li>
          </ol>
          <i>
            <b>Important Considerations:</b>
          </i>
          <ul>
            <li>
              Regularly review and update allocations to ensure they accurately
              reflect the dynamics of your business.
            </li>
            <li>
              Confirm that the allocated budgets and expenses align with your
              business strategy and objectives.
            </li>
            <li>
              Utilize the department-wise view of the P&L/Income Statement to
              make informed decisions and optimize resource allocation.
            </li>
          </ul>
          By following these steps, you can leverage the allocation features in
          Seqnc to gain a detailed and insightful view of how each department
          contributes to the overall financial picture of your business.
        </SectionContent>
        <SubSectionHeader title='D&A allocation'>
          <SectionContent>
            <p>
              Seqnc offers versatile options for allocating and displaying
              Depreciation & Amortization (D&A) expenses in your financial
              model. Tailor the representation to align with your reporting
              preferences and organizational structure.
            </p>
            <ImageContainer fileName='d&aallocations.png' alt='Capital Plan' />
            <b>Display Options:</b>
            <ol>
              <li>
                Display Separate Line Items:
                <ul>
                  <li>
                    Choose this option if you prefer to display D&A related to
                    Cost of Sales and Operating Expenses as separate line items.
                    This provides a detailed breakdown of depreciation expenses
                    associated with specific cost categories.
                  </li>
                </ul>
              </li>
              <li>
                Display Total D&A in a Consolidated OpEx Line Item:
                <ul>
                  <li>
                    Opt for this option if you want to consolidate all
                    Depreciation & Amortization expenses into a single line item
                    under Operating Expenses. This provides a summarized view of
                    D&A without specific category details.
                  </li>
                </ul>
              </li>
              <li>
                Allocate All D&A Expenses by Department:
                <ul>
                  <li>
                    Select this option if you wish to allocate all Depreciation
                    & Amortization expenses by department. This option does not
                    display D&A as separate line items; instead, it's
                    distributed across departments.
                  </li>
                </ul>
              </li>
            </ol>
            <b>Steps to Configure D&A Allocation:</b>
            <ol>
              <li>
                Navigate to D&A Allocation Section:
                <ul>
                  <li>
                    Access the 'D&A Allocation' section within the Seqnc
                    application.
                  </li>
                </ul>
              </li>
              <li>
                Select Display Option:
                <ul>
                  <li>
                    Choose the appropriate display option based on your
                    reporting and analysis needs.
                  </li>
                </ul>
              </li>
              <li>
                Configure Allocation Method:
                <ul>
                  <li>Display Separate Line Items</li>
                  <li>Display Total D&A in a Consolidated OpEx Line Item</li>
                  <li>
                    Allocate All D&A Expenses by Department: Allocate the
                    percentage by department for direct/opex items
                  </li>
                </ul>
              </li>
            </ol>
          </SectionContent>
        </SubSectionHeader>
        <SubSectionHeader title='Allocation of commissions'>
          <SectionContent>
            <p>
              Effectively allocating budgeted commission amounts by department
              is crucial for accurate financial planning and analysis in Seqnc.
              This guide outlines the steps to allocate commissions and provides
              flexibility to accommodate various departmental structures.
            </p>
            <ImageContainer
              fileName='allocationofcomm.png'
              alt='Capital Plan'
            />
            <p>
              This section allows you to allocate your budgeted commissions
              amounts by department, providing granular insights into how
              commission expenses are distributed across different areas of your
              organization.
            </p>
            <b>Steps to Allocate Commissions by Department:</b>
            <ol>
              <li>
                Navigate to 'Allocation of commissions' Section
                <ul>
                  <li>
                    Access the 'Allocation of commissions' tab under
                    'Allocations' section within the Seqnc application.
                  </li>
                </ul>
              </li>
              <li>
                Specify Allocation Percentages:
                <ul>
                  <li>
                    Specify the allocation percentages for each department.
                  </li>
                </ul>
              </li>
              <li>
                Validation and Adjustment:
                <ul>
                  <li>
                    Review the default settings, if any, and adjust the
                    allocation percentages as needed. Ensure that the total
                    allocations sum up to 100%.
                  </li>
                </ul>
              </li>
            </ol>
            <b>Default Allocations:</b>
            <p>
              The model does not make any default allocations for commissions.
              It is your responsibility to specify the allocation percentages
              based on your organization's commission structure.
            </p>
            <p>
              Regularly review and update commission allocations to align with
              changes in organizational structures or commission policies.
            </p>
          </SectionContent>
        </SubSectionHeader>
        <SubSectionHeader title='Allocation of Customer Acquisition Cost'>
          <SectionContent>
            <p>
              Efficiently managing and allocating Customer Acquisition Costs
              (CAC) by product is crucial for accurate financial planning. Seqnc
              provides features to specify, validate, and allocate CAC by
              product, enhancing visibility into the cost structure associated
              with customer acquisition.
            </p>
            <ImageContainer fileName='allocationofcac.png' alt='Capital Plan' />
            <p>
              This section allows you to allocate CAC by product, providing
              insights into the distribution of customer acquisition costs
              across different products.
            </p>
            <b>Steps to Allocate CAC by Product:</b>
            <ol type='1'>
              <li>
                Specify Commissions Inclusion:
                <ul>
                  <li>
                    Indicate whether commissions should be included in the cost
                    of customer acquisition. Choose 'Yes' or 'No' based on your
                    organization's commission structure.
                  </li>
                </ul>
              </li>
              <li>
                Specify Customer Acquisition Cost (CAC):
                <ul>
                  <li>
                    For each cost item, determine whether all or part of it is a
                    customer acquisition cost. Choose 'Yes' or 'No' for the 'Is
                    all or part of this a customer acquisition cost' option.
                  </li>
                </ul>
              </li>
              <li>
                Allocate CAC by Product:
                <ul>
                  <li>
                    If the cost is identified as a customer acquisition cost,
                    allocate CAC by product using percentage inputs. Specify the
                    percentage allocated to each product, ensuring that the
                    total sum is 100%.
                  </li>
                </ul>
              </li>
              <li>
                Validation and Adjustment:
                <ul>
                  <li>
                    Validate the allocation percentages and adjust as necessary
                    to ensure they align with your organization's CAC allocation
                    strategy.
                  </li>
                </ul>
              </li>
            </ol>
            <b>Important Considerations:</b>
            <ol>
              <li>
                Commissions Inclusion:
                <ul>
                  <li>
                    Clearly define whether commissions should be included in the
                    cost of customer acquisition, and select the appropriate
                    option.
                  </li>
                </ul>
              </li>
              <li>
                Validation Checks:
                <ul>
                  <li>
                    Validate that the total allocation percentages for CAC by
                    product sum up to 100%
                  </li>
                </ul>
              </li>
              <li>
                Periodically review and update CAC allocations to adapt to
                changes in product strategies or customer acquisition
                approaches.
              </li>
            </ol>
          </SectionContent>
        </SubSectionHeader>
      </SectionHeader>
    </React.Fragment>
  );
};
