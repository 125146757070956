export const Parts = Object.freeze({
  INTRODUCTION: "/",
  SIGNUP: "/signup",
  ONBOARD: "/onboard",
  MODULES: "/modules",
  FORECASTS: "/modules/forecasts",
  COLLABORATION_COPIES: "/modules/collaborationCopies",
  WORKSPACE: "/modules/workspace",
  CONNECT_TO_OTHER_APPLICATIONS: "/modules/connectToOtherApplications",
  MANAGE_ACCOUNT: "/modules/manageAccounts",
  MANAGE_TEAM: "/modules/manageTeam",
  CREATE_MODEL: "/createModel",
  PRODUCT_SERVICES: "/createModel/productAndServices",
  JOB_ROLES: "/createModel/jobRoles",
  EXPENSES: "/createModel/expenses",
  INVENTORIES: "/createModel/inventoryPurchases",
  FIXED_ASSETS: "/createModel/fixedAssets",
  FORMAT_VIEW: "/createModel/formatViewSettings",
  INPUT_ASSUMPTIONS: "/inputAssumptions",
  GENERAL_ASSUMPTIONS: "/inputAssumptions/generalAssumptions",
  INPUT_SPREADSHEET: "/inputAssumptions/inputSpreadSheet",
  OTHER_ASSUMPTIONS: "/inputAssumptions/otherAssumptions/",
  STARTING_BS_SHEET: "/inputAssumptions/otherAssumptions/startingBalanceSheet",
  CAPITAL_PLAN: "/inputAssumptions/otherAssumptions/capitalPlan",
  ALLOCATIONS: "/inputAssumptions/otherAssumptions/allocations",
  REPORTS: "/reports",
  FINANCIAL_PROJECTIONS: "/reports/financialProjections",
  BILLING_AND_REVENUES: "/reports/billingAndRevenues",
  CAPITAL_REQUIRED: "/reports/capitalRequired",
  SUBSCRIPTION_METRICS: "/reports/subscriptionMetrics",
  SUMMARY: "/reports/summary",
  SCENARIO_COMPARISION: "/reports/scenarioComparison",
  TRACK_PERFORMANCE: "/trackPerformance",
  CONFIGURE_DATASOURCE: "/trackPerformance/configureDatasource",
  FORECAST_TO_ACTUAL: "/trackPerformance/forecastToActual",
  ROLLING_FORECAST: "/trackPerformance/rollingForecast",
  WATCH_WINDOW: "/watchWindow",
  // SETTINGS: "/settings",
  // COMPANY_PROFILE: "/settings/companyProfile",
  // MANAGE_FORECAST_SCENARIOS: "/settings/manageForecastScenarios",
  // SAVE_SHARE_YOUR_MODEL: "saveShareYourModel",
  // CREATE_SHARE_TEMPLATE: "createShareTemplate",
  // IMPORT_MODEL: "importModel",
  // MANAGE_ACCOUNT_TEAM: "/settings/manageAccountAndTeam",
  // SWITCH_ACCOUNT: "/settings/switchAccount",
});
