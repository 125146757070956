import React from "react";
import { SectionHeader } from "../../../common/SectionHeader";
import { SectionContent } from "../../../common/SectionContent";
import { ImageContainer } from "../../../common/ImageContainer";
import BulkAddNote from "../../../common/BulkAddNote";
import GoToLink from "../../../common/GoToLink";
export const FixedAssets = () => {
  return (
    <React.Fragment>
      <SectionHeader title="FIXED ASSETS/CAPEX">
        <SectionContent>
          <p>{`Upon entering the Fixed Assets/CapEx page, a summary table will be
          displayed, providing an overview of your fixed asset and capital
          expenditure records. Add a line for each type of fixed asset purchases/capital expenditure in this section. Seqnc will add a corresponding line in the input spreadsheet that you add so you can provide assumptions for each line item. You can add any number of fixed asset purchase line items. For example, you can add different line items for different fixed asset purchase categories so that you can define their attributes uniquely`}</p>
          <GoToLink
            goToLink="/app/fixed-assets"
            goToText="Fixed assets/Capital Expenditures"
            lineItemName="Fixed asset/Capital Expenditure"
          />
          <ImageContainer fileName="fixedAssets.png" alt="Fixed Assets" />
          <ImageContainer fileName="add-fixed-assets.png" alt="Fixed Assets" />
          <BulkAddNote lineItemName="Fixed Assets" />
          <p>
            To access the Fixed Assets/CapEx module:
            <ol>
              <li>
                Navigate to the "Create Model" section in the left navigation
                menu.
              </li>
              <li>Select "Fixed Assets/CapEx."</li>
            </ol>
          </p>
          <p>
            To add fixed assets or capital expenditures:
            <ol>
              <li>
                Click on the "Add Fixed Asset/Capital Expenditure" button.
              </li>
              <li>Fill in the necessary details for each line item.</li>
            </ol>
          </p>
          <p>
            Update or Delete:
            <ol>
              <li>Locate the specific fixed asset in the summary table.</li>
              <li>Utilize the edit icon associated with the asset.</li>
              <li>
                Choose between updating or deleting the fixed asset item based
                on your requirements.
              </li>
            </ol>
          </p>
          <p>
            Input Fields for Fixed Asset Purchase/CapEx
            <p>
              When adding details for each fixed asset purchase or capital
              expenditure line item, you will encounter the following input
              fields:
            </p>
            <ol>
              <li>
                <i> Fixed Asset Purchase Description: </i>Provide a brief and
                clear description of the fixed asset purchase. This description
                helps identify and differentiate each line item.
              </li>
              <li>
                <i>Depreciation Term (in Years): </i>Specify the anticipated
                lifespan or useful life of the fixed asset in years. This is
                used to calculate depreciation.
              </li>
              <li>
                <i> A/P Days for This Fixed Asset Purchase: </i>Indicate the
                accounts payable days associated with this particular fixed
                asset purchase. This helps in managing the payment terms for the
                acquisition.
              </li>
              <li>
                <i> Specify Planned Usage for This Fixed Asset: </i>Describe the
                intended purpose or usage of the fixed asset. This information
                can be crucial for planning and budgeting. Select "General
                operations" if the fixed asset/capital expenditure is for
                general operations; this would apply for most fixed asset
                purchases if you are not involved in manufacturing. Select
                "Production/Manufacturing of goods or services" if the fixed
                asset will be used directly to manufacture goods or produce
                services; this would generally apply only in specific equipment
                such as factory equipment
              </li>
              <li>
                <i>
                  {" "}
                  Salvage Value of This Fixed Asset Purchase (as % of Asset
                  Cost):{" "}
                </i>
                Define the estimated residual or salvage value of the fixed
                asset as a percentage of its initial cost. This is important for
                depreciation calculations.
              </li>
              <li>
                <i> Select a Depreciation Method: </i>Choose the depreciation
                method that aligns with your accounting practices. Options may
                include straight line, sum of years, declining balance methods.
                We compute the depreciation amounts by period according to your
                selection.
              </li>
              <li>
                <i>
                  Specify Declining Balance Factor for Selected Method
                  (Applicable if "Select a Depreciation Method" is "Declining
                  Balance"):{" "}
                </i>
                If you choose the declining balance depreciation method, specify
                the factor to determine the rate of decline. This factor
                influences the depreciation amount. For example, specify “2” if
                you would like to use double declining balance.
              </li>
            </ol>
          </p>
          <p>
            Input Assumptions:
            <ol>
              <li>
                Navigate to the 'Input Spreadsheet' under the 'Input
                Assumptions' section in the left navigation menu.
              </li>
              <li>
                Enter assumptions and details for each fixed asset purchase,
                considering monthly variations in the "Fixed asset purchases"
                sheet.
              </li>
            </ol>
          </p>
        </SectionContent>
      </SectionHeader>
    </React.Fragment>
  );
};
