import React from "react";
import { SubSectionHeader } from "../../../../common/SubSectionHeader";
import { SectionContent } from "../../../../common/SectionContent";
import { ImageContainer } from "../../../../common/ImageContainer";

export const PriorFixedAssets = () => {
  return (
    <React.Fragment>
      <SubSectionHeader title='PRIOR FIXED ASSETS' id='priorFixedAssets'>
        <SectionContent>
          <p>
            If you have net fixed assets in your starting balance sheet and wish
            to include ongoing depreciation, add one or more prior fixed asset
            purchase items in this section. Each fixed asset purchase item
            corresponds to an existing fixed asset or group of assets with its
            own schedule. Ensure that the total amount of prior fixed asset
            purchases added in this section is less than or equal to your
            starting balance sheet amount. The model will automatically
            calculate and include all amounts for depreciation for each prior
            fixed asset you add.
          </p>
          <ImageContainer
            fileName='priorFixedAsssets.png'
            alt='Prior FixedAssets'
          />
          <p>{`Below are the fields you can input while creating prior fixed assets`}</p>
          <table>
            <tr>
              <td>
                <b>Fixed asset purchase description</b>
              </td>
              <td>{`Provide a description for the fixed asset purchase/capital expenditure`}</td>
            </tr>
            <tr>
              <td>
                <b>Depreciation term(in years)</b>
              </td>
              <td>{`This is the term (in years) over which the asset you are purchasing should be depreciated.`}</td>
            </tr>
            <tr>
              <td>
                <b>Specify planned usage for this fixed asset</b>
              </td>
              <td>{`Specify if the asset is acquired for general operations of it is to be used in the production/delivery of products and services. The depreciation associated with this asset will be tracked as either related to operating expenses of cost of sales, respectively, according to your selection.`}</td>
            </tr>
            <tr>
              <td>
                <b>% of prior assets</b>
              </td>
              <td>{`Percentage of prior assets`}</td>
            </tr>
            <tr>
              <td>
                <b>
                  Salvage value of this fixed asset purchase (as % of asset
                  cost)
                </b>
              </td>
              <td>{`The salvage value, expressed as a % of the cost of acquiring the asset, is the book value of the asset at the end of the depreciation term.`}</td>
            </tr>
            <tr>
              <td>
                <b>Select a depreciation method</b>
              </td>
              <td>{`Our model allows you to select from three commonly-used depreciation methods: straight line, sum of years, or double declining. We compute the depreciation amounts by period according to your selection.`}</td>
            </tr>
            <tr>
              <td>
                <b>Specify declining balance factor for selected method</b>
              </td>
              <td>{`In case you select the declining balance method you can specify the declining factor as an integer. For example, specify “2” if you would like to use double declining balance.`}</td>
            </tr>
          </table>
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
