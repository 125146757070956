import React from "react";
import { SubSectionHeader } from "../../../../common/SubSectionHeader";
import { SectionContent } from "../../../../common/SectionContent";
import { ImageContainer } from "../../../../common/ImageContainer";

export const PriorDebt = () => {
  return (
    <React.Fragment>
      <SubSectionHeader title='PRIOR DEBT' id='priorDebt'>
        <SectionContent>
          <p>
            If you have debt in your starting balance sheet and wish to include
            ongoing interest and principal payments, add one or more prior debt
            items in this section. Each debt item corresponds to an existing
            tranche of debt with its own terms. Ensure that the total amount of
            prior debt added in this section is less than or equal to your
            starting balance sheet debt. The model will automatically calculate
            all amounts for the current portion, repayment, interest, and
            remaining portion for each prior debt line item you add.
          </p>
          <ImageContainer fileName='priorDebt.png' alt='Prior Debt' />
          <p>{`Below are the fields you can input while creating prior debt`}</p>
          <table>
            <tr>
              <td>
                <b>Debt description</b>
              </td>
              <td>{`Line item description will be displayed in financial statements`}</td>
            </tr>
            <tr>
              <td>
                <b>Type of debt</b>
              </td>
              <td>{`You can select Term debt, Convertible note, or venture debt`}</td>
            </tr>
            <tr>
              <td>
                <b>If convertible note, assume repay or convert at maturity?</b>
              </td>
              <td>{`If this is a convertible note, do you want to assume this debt would be repaid or converted to equity?`}</td>
            </tr>
            <tr>
              <td>
                <b>% of starting b/s debt</b>
              </td>
              <td>{`Percentage of debt specified in starting b/s`}</td>
            </tr>
            <tr>
              <td>
                <b>Month in which debt is planned to be raised</b>
              </td>
              <td>{`The month in which you expect to raise this debt`}</td>
            </tr>
            <tr>
              <td>
                <b>Remaining term</b>
              </td>
              <td>{`Remaining term of debt`}</td>
            </tr>
            <tr>
              <td>
                <b>Interest rate on debt</b>
              </td>
              <td>{`Annual interest rate on the debt`}</td>
            </tr>
            <tr>
              <td>
                <b>Interest calculation method</b>
              </td>
              <td>{`Select from Equal payment, Equal principal, Interest payments only -Principal paid at maturity, or Interest & Principal accrued until maturity.`}</td>
            </tr>
            <tr>
              <td>
                <b>Interest only period (max: term - 1)</b>
              </td>
              <td>{`Interest only period: The number of months in which you will only pay interest (if applicable)`}</td>
            </tr>
            <tr>
              <td>
                <b>Exit fee</b>
              </td>
              <td>{`Exit fees as % of debt, if applicable`}</td>
            </tr>
          </table>
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
