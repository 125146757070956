import React from "react";
import { Outlet } from "react-router-dom";

type Props = { toggleNavPanel: boolean };
export const RightPanel = (props: Props) => {
  const { toggleNavPanel } = props;

  return (
    <React.Fragment>
      <div>
        <div
          className={
            // toggleNavPanel ? "sq-right-content" : "sq-right-content-full"
            "sq-right-content"
          }
        >
          <Outlet />
        </div>
      </div>
    </React.Fragment>
  );
};
