import React from "react";
import { Section } from "./Section";
import { LinkGroup } from "./LinkGroup";
import { NavLink } from "./NavLink";
import { Parts } from "./Path";
import { Subsection } from "./Subsection";

type Props = {
  currentLink: string;
  setCurrentLink: Function;
};

export const NavigationMenu = (props: Props) => {
  const { currentLink, setCurrentLink } = props;

  return (
    <>
      <div className='sq-nav'>
        <ul>
          <Section
            liId='introduction'
            spanId='introduction'
            displayName='INTRODUCTION'
            openByDefault={false}
            path={Parts.INTRODUCTION}
            currentLink={currentLink}
            setCurrentLink={setCurrentLink}
          ></Section>
          <Section
            liId='signup'
            spanId='signup'
            displayName='SIGNUP'
            openByDefault={false}
            path={Parts.SIGNUP}
            currentLink={currentLink}
            setCurrentLink={setCurrentLink}
          ></Section>
          <Section
            liId='onboard'
            spanId='onboard'
            displayName='ONBOARD'
            openByDefault={false}
            path={Parts.ONBOARD}
            currentLink={currentLink}
            setCurrentLink={setCurrentLink}
          ></Section>
          <Section
            liId='modules'
            spanId='modules'
            displayName='MODULES'
            openByDefault={false}
            path={Parts.MODULES}
            currentLink={currentLink}
            setCurrentLink={setCurrentLink}
          >
            <LinkGroup>
              <NavLink
                className='navigator'
                id='workspace'
                path={Parts.WORKSPACE}
                displayName='Workspace'
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              />
              <NavLink
                className='navigator'
                id='forecasts'
                path={Parts.FORECASTS}
                displayName='Forecasts & scenarios'
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              />
              <NavLink
                className='navigator'
                id='copies'
                path={Parts.COLLABORATION_COPIES}
                displayName='Collaboration & copies'
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              />

              <NavLink
                className='navigator'
                id='connect_to_other_applications'
                path={Parts.CONNECT_TO_OTHER_APPLICATIONS}
                displayName='Connect to other applications'
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              />
              <NavLink
                className='navigator'
                id='manage_account'
                path={Parts.MANAGE_ACCOUNT}
                displayName='Manage account/company'
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              />
              <NavLink
                className='navigator'
                id='manage_team'
                path={Parts.MANAGE_TEAM}
                displayName='Manage team'
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              />
            </LinkGroup>
          </Section>
          <Section
            liId='createModel'
            spanId='createModel'
            displayName='CREATE MODEL'
            openByDefault={false}
            path={Parts.CREATE_MODEL}
            currentLink={currentLink}
            setCurrentLink={setCurrentLink}
          >
            <LinkGroup>
              <NavLink
                key='product_services'
                className='navigator'
                id='product_services'
                path={Parts.PRODUCT_SERVICES}
                displayName='Product & Services'
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              />
              <NavLink
                key='job_roles'
                className='navigator'
                id='job_roles'
                path={Parts.JOB_ROLES}
                displayName='Job/Roles & Departments'
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              />
              <NavLink
                className='navigator'
                id='cost_expenses'
                path={Parts.EXPENSES}
                displayName='Cost & Expenses'
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              />
              <NavLink
                className='navigator'
                id='inventory_purchases'
                path={Parts.INVENTORIES}
                displayName='Inventory purchases'
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              />
              <NavLink
                className='navigator'
                id='fixed_assets'
                path={Parts.FIXED_ASSETS}
                displayName='Fixed Assets/CapEx'
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              />
              <NavLink
                className='navigator'
                id='format_view'
                path={Parts.FORMAT_VIEW}
                displayName='Format/View Settings'
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              />
            </LinkGroup>
          </Section>
          <Section
            liId='inputAssumptions'
            spanId='inputAssumptions'
            displayName='INPUT ASSUMPTIONS'
            openByDefault={false}
            path={Parts.INPUT_ASSUMPTIONS}
            currentLink={currentLink}
            setCurrentLink={setCurrentLink}
          >
            <LinkGroup>
              <NavLink
                className='navigator'
                id='general_assumptions'
                path={Parts.GENERAL_ASSUMPTIONS}
                displayName='General Assumptions'
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              />
              <NavLink
                className='navigator'
                id='input_spreadsheet'
                path={Parts.INPUT_SPREADSHEET}
                displayName='Input spreadsheet'
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              />
              <Subsection
                id={"other_assumptions"}
                displayName='Other assumptions'
                path={Parts.OTHER_ASSUMPTIONS}
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              >
                <LinkGroup>
                  <NavLink
                    id={"starting_balance_sheet"}
                    path={`${Parts.STARTING_BS_SHEET}`}
                    data-tour='startingAndOther-link'
                    displayName='Starting Balance Sheet'
                    currentLink={currentLink}
                    setCurrentLink={setCurrentLink}
                  />

                  <NavLink
                    id={"capital_plan"}
                    path={`${Parts.CAPITAL_PLAN}`}
                    data-tour='capital-input-link'
                    displayName='Capital plan'
                    currentLink={currentLink}
                    setCurrentLink={setCurrentLink}
                  />
                  <NavLink
                    id={"allocations"}
                    path={Parts.ALLOCATIONS}
                    sq-parent-menu-id='other_assumptions'
                    data-tour='allocations-link'
                    displayName='Allocations'
                    currentLink={currentLink}
                    setCurrentLink={setCurrentLink}
                  />
                </LinkGroup>
              </Subsection>
            </LinkGroup>
          </Section>
          <Section
            liId='reports'
            spanId='reports'
            displayName='REPORTS'
            openByDefault={false}
            path={Parts.REPORTS}
            currentLink={currentLink}
            setCurrentLink={setCurrentLink}
          >
            <LinkGroup>
              <NavLink
                key='financial projections'
                className='navigator'
                id='financial_projections'
                path={Parts.FINANCIAL_PROJECTIONS}
                displayName='Financial projections'
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              />
              <NavLink
                key='job_roles'
                className='navigator'
                id='job_roles'
                path={Parts.BILLING_AND_REVENUES}
                displayName='Billings & Revenues'
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              />
              <NavLink
                className='navigator'
                id='cost_expenses'
                path={Parts.CAPITAL_REQUIRED}
                displayName='Capital required'
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              />
              <NavLink
                className='navigator'
                id='inventory_purchases'
                path={Parts.SUBSCRIPTION_METRICS}
                displayName='Subscription metrics'
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              />
              <NavLink
                className='navigator'
                id='fixed_assets'
                path={Parts.SUMMARY}
                displayName='Summary'
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              />
              <NavLink
                className='navigator'
                id='format_view'
                path={Parts.SCENARIO_COMPARISION}
                displayName='Scenario comparison'
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              />
            </LinkGroup>
          </Section>
          <Section
            liId='trackPerformance'
            spanId='trackPerformance'
            displayName='TRACK PERFORMANCE'
            openByDefault={false}
            path={Parts.TRACK_PERFORMANCE}
            currentLink={currentLink}
            setCurrentLink={setCurrentLink}
          >
            <LinkGroup>
              <NavLink
                className='navigator'
                id='configure_data_source'
                path={Parts.CONFIGURE_DATASOURCE}
                displayName='Configure data source'
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              />
              <NavLink
                className='navigator'
                id='forecast_to_actual'
                path={Parts.FORECAST_TO_ACTUAL}
                displayName='Forecast to actual'
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              />
              <NavLink
                className='navigator'
                id='rolling_forecast'
                path={Parts.ROLLING_FORECAST}
                displayName='Rolling forecast'
                currentLink={currentLink}
                setCurrentLink={setCurrentLink}
              />
            </LinkGroup>
          </Section>
          <Section
            liId='watch-window'
            spanId='watch-window'
            displayName='WATCH WINDOW'
            openByDefault={false}
            path={Parts.WATCH_WINDOW}
            currentLink={currentLink}
            setCurrentLink={setCurrentLink}
          />
        </ul>
      </div>
    </>
  );
};
