import React from "react";
import { SectionHeader } from "../../common/SectionHeader";
import { SectionContent } from "../../common/SectionContent";
import { ImageContainer } from "../../common/ImageContainer";

export const Modules = () => {
  return (
    <React.Fragment>
      <SectionHeader title='Modules'>
        <SectionContent>
          <p>{`The application is structured with the following five modules for a user within a company:`}</p>
          <ol type='1'>
            <li>
              Forecasts
              <ul>
                <li>
                  Creation of Forecasts:
                  <ul>
                    <li>
                      Users have the ability to create an unlimited number of
                      forecasts under a specific company.
                    </li>
                    <li>
                      The total number of forecasts is subject to the user's
                      subscription plan and the 'Add Forecast/s' add-on.
                    </li>
                  </ul>
                </li>
                <li>
                  Forecast States:
                  <ul>
                    <li>
                      Forecasts can exist in two states: 'Saved' and 'In
                      Progress.'
                    </li>
                    <li>
                      Saved Forecast:
                      <ul>
                        <li>
                          Changes made to the forecast in the workspace must be
                          explicitly saved.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Sharing Options:
                      <ul>
                        <li>
                          Users can share forecasts with others as a separate
                          copy.
                        </li>
                        <li>
                          Shared copies can be sent to users via email
                          addresses.
                        </li>
                        <li>
                          Collaboration access to users on in-progress forecasts
                          is facilitated for real-time collaboration.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Scenario Management:
                      <ul>
                        <li>
                          Each forecast comprises a set of scenarios that serve
                          as input parameters.
                        </li>
                        <li>
                          The total number of scenarios is subject to the user's
                          subscription plan and the 'Additional Scenario/s'
                          add-on.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Copies
              <ul>
                <li>
                  Copy Creation and Sharing:
                  <ul>
                    <li>
                      Users can create a copy from an existing forecast and
                      share it with others.
                    </li>
                    <li>Sharing is done through email addresses.</li>
                  </ul>
                </li>
                <li>
                  Importing Shared Copies:
                  <ul>
                    <li>
                      Users can import shared copies using the "Create a New
                      Forecast Form" and selecting the 'Import a Model Shared
                      with Me' option.
                    </li>
                  </ul>
                </li>
                <li>
                  Copy Modification:
                  <ul>
                    <li>
                      Shared copies can be managed using the 'Edit Copy Form'
                      allowing users to make necessary modifications.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Workspace
              <ul>
                <li>
                  The 'Workspace' module serves as a central area where users
                  can create or open existing forecasts to update financial
                  models. It provides a collaborative environment for efficient
                  model editing.
                </li>
              </ul>
              <ul>
                <li>
                  Workspace Creation:
                  <ul>
                    <li>
                      Every user is provided with a dedicated workspace under a
                      specific company.
                    </li>
                  </ul>
                </li>
                <li>
                  Functionality:
                  <ul>
                    <li>
                      Workspaces serve as a centralized location for managing
                      and updating in-progress forecasts.
                    </li>
                    <li>
                      Users can seamlessly switch between different forecasts
                      within their workspace.
                    </li>
                  </ul>
                </li>
                <li>
                  Collaboration:
                  <ul>
                    <li>
                      Invitations for collaboration can be extended to other
                      users within the workspace.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Connect to Other Applications:
              <ul>
                <li>
                  This module facilitates integration with other applications,
                  enhancing data connectivity and allowing users to streamline
                  their financial processes.
                </li>
              </ul>
            </li>
            <li>
              Manage Account and Team:
              <ul>
                <li>
                  These modules, 'Manage Account' and 'Manage Team,' empower
                  users to control and customize their account settings and team
                  collaboration within the application.
                </li>
              </ul>
            </li>
          </ol>
          These modules collectively offer a comprehensive and user-centric
          experience for efficient financial planning and management.
          <ImageContainer fileName='modules.png' alt='Modules' />
        </SectionContent>
      </SectionHeader>
    </React.Fragment>
  );
};
