import React from "react";

type Props = {
  children: any;
};

export const LinkGroup = (props: Props) => {
  return (
    <ul>
      <li data-tour=''>{props.children}</li>
    </ul>
  );
};
