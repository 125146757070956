import React from "react";
import { SubSectionHeader } from "../../../common/SubSectionHeader";
import { SectionContent } from "../../../common/SectionContent";
import { ImageContainer } from "../../../common/ImageContainer";

export const Drivers = () => {
  return (
    <React.Fragment>
      <SubSectionHeader title='DRIVERS'>
        <SectionContent>
          <p>
            Seqnc enhances user convenience by automatically adding a summary
            sheet to the input spreadsheet, incorporating commonly-used model
            drivers. Users can leverage any 'Driver' row to define one or more
            rows whose values are contingent on a driver value. For instance, if
            there is a cost that is a fixed percentage of revenues, users can
            link the cost row to the revenue driver row, enabling automatic
            computation of the cost.
          </p>
          <p>
            It's important to note that the 'Driver' sheet is locked, preventing
            direct modifications. However, all values within the 'Driver' sheet
            are dynamically updated whenever there is a change in inputs. This
            ensures that any values computed based on the 'Driver' sheet remain
            current, maintaining accuracy and consistency throughout the model.
          </p>
          <ImageContainer fileName='drivers.png' alt='Drivers' />
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
