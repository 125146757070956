import React from "react";
import { SectionHeader } from "../../../../common/SectionHeader";
import { SectionContent } from "../../../../common/SectionContent";
import { Equity } from "./Equity";
import { NewDebt } from "./NewDebt";
import { LOCDebt } from "./LOCDebt";
import { StockRepurchase } from "./StockRepurchase";
import { ImageContainer } from "../../../../common/ImageContainer";

export const CapitalPlan = () => {
  return (
    <React.Fragment>
      <SectionHeader title='CAPITAL PLAN'>
        <SectionContent>
          <p>
            Provide a comprehensive overview of your capitalization plan in this
            section. Specify the amounts of equity, debt, line of credit, and
            stock repurchase, including their timing and terms, for each tranche
            of capital you plan to raise during the forecast period.
          </p>
          <ImageContainer fileName='capitalPlan.png' alt='Capital Plan' />
        </SectionContent>
      </SectionHeader>
      <Equity />
      <NewDebt />
      <LOCDebt />
      <StockRepurchase />
    </React.Fragment>
  );
};
