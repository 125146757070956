import { Typography } from "@mui/material";
import React from "react";

export const SectionContent = (props) => {
  return (
    <React.Fragment>
      <Typography className='docContent' align='justify'>
        {props.children}
      </Typography>
    </React.Fragment>
  );
};
