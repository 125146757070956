import React from "react";
import { SectionHeader } from "../../../common/SectionHeader";
import { SectionContent } from "../../../common/SectionContent";
import { ImageContainer } from "../../../common/ImageContainer";
import { SubSectionHeader } from "../../../common/SubSectionHeader";
import { ManageForecastScenarios } from "../../settings/ManageForecastScenarios";

export const Forecasts = () => {
  return (
    <React.Fragment>
      <SectionHeader title='Forecasts'>
        <SectionContent>
          <p>
            A forecast in your company is a personalized and saved financial
            model, created to meet the unique needs of individual users and
            their specific companies. Users can create multiple forecasts,
            offering flexibility in effectively managing diverse financial
            scenarios. To update a forecast, users can open it in the workspace,
            make necessary changes, and then write back the modifications.
            Additionally, the collaborative nature of the platform allows for
            seamless sharing of financial insights by creating and sharing
            forecast copies with other users.
          </p>
        </SectionContent>
      </SectionHeader>
      <SubSectionHeader title='Accessing Forecast Management'>
        <SectionContent>
          <p>
            Navigate to the forecast management page through either of the
            following routes:
          </p>
          <ol type='1'>
            <li>"Manage forecasts and scenarios" under "Settings"</li>
            <li>"Manage" link in the left navigation menu</li>
          </ol>
          <ImageContainer
            fileName='manage-forecasts.png'
            alt='Manage forecasts'
            style={{ height: "350px" }}
          />
        </SectionContent>
      </SubSectionHeader>
      <SubSectionHeader
        title='Key Sections on the Forecast Management Page'
        id='my_saved_models'
      >
        <SectionContent>
          <ol type='1'>
            <li>
              Active Forecasts:
              <ul>
                <li>View and manage all active forecasts.</li>
                <li>Easily switch between active forecasts.</li>
                <li>Save modifications to the forecast as needed.</li>
              </ul>
            </li>
            <li>
              Manage Scenarios:
              <ul>
                <li>
                  View, create, edit, and delete scenarios for each forecast.
                </li>
              </ul>
            </li>
            <li>
              Saved Forecasts:
              <ul>
                <li>Lists all forecasts that have saved changes.</li>
              </ul>
            </li>
            <li>
              Inactive Forecasts:
              <ul>
                <li>
                  Lists forecasts that are in a disabled state based on the
                  subscription plan and add-ons.
                </li>
                <li>
                  To enable disabled forecasts, upgrade the plan or purchase the
                  'Add forecast/s' add-on.
                </li>
              </ul>
            </li>
          </ol>
          <p>
            This centralized forecasting feature empowers users to tailor
            financial models to their company's needs, fostering collaboration
            and providing a comprehensive toolset for effective financial
            management.
          </p>
        </SectionContent>
      </SubSectionHeader>
      <SubSectionHeader
        title='Creating a New Forecast'
        id='creating-new-forecast'
      >
        <SectionContent>
          <ImageContainer
            fileName='create-forecast.png'
            alt='Create forecast'
            style={{ height: "350px" }}
          />
          <ol>
            <li>
              Accessing the 'Create a New Forecast' Link:
              <ul>
                <li>Navigate to the left navigation menu.</li>
                <li>Look for the 'Create a New Forecast' link.</li>
                <li>
                  Alternatively, you can access the forecasting options under
                  'Settings' by selecting 'Manage Forecast and Scenarios.'
                </li>
              </ul>
            </li>
            <li>
              Subscription Plan and Add-Ons:
              <ul>
                <li>
                  Ensure that your subscription plan allows the creation of a
                  new forecast.
                </li>
                <li>
                  Confirm the number of available 'Add Forecast/s' add-ons based
                  on your subscription level.
                </li>
              </ul>
            </li>
            <li>
              Choose a Creation Option:
              <p>
                Once in the forecasting section, you will be prompted to choose
                one of the following three options:
              </p>
              <ul>
                <li>
                  Option 1: Create a New Blank Model
                  <ul>
                    <li>
                      Select this option if you want to start with a completely
                      new forecast with no predefined line items. This is useful
                      for building a forecast from scratch.
                    </li>
                  </ul>
                </li>
                <li>
                  Option 2: Import a Model Shared with Me
                  <ul>
                    <li>
                      Choose this option if you have received a shared model and
                      want to create a forecast based on a copy of that shared
                      model. This helps maintain consistency and collaboration
                      with others.
                    </li>
                  </ul>
                </li>
                <li>
                  Option 3: Import Account from QBO/XERO/Zoho Books
                  <ul>
                    <li>
                      Opt for this option if you want to import line items
                      directly from real-time accounting systems such as
                      QuickBooks Online (QBO) / Xero / Zoho Books. Authorize the
                      connection to import relevant data seamlessly.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ol>
          <p>
            Once the new forecast is created and saved, you can start entering
            data, making predictions, or collaborating with users.
          </p>
        </SectionContent>
      </SubSectionHeader>
      <SubSectionHeader title='Save changes' id='save-changes'>
        <SectionContent>
          <ImageContainer
            fileName='save-forecast.png'
            alt='Save forecast'
            style={{ height: "350px" }}
          />
          <ol>
            <li>
              Accessing the 'Save Changes' Link:
              <ul>
                <li>
                  Navigate to the left navigation menu and locate the 'Save
                  Changes' link. This link is often available to save any
                  modifications made within the workspace.
                </li>
                <li>
                  Alternatively, you can find the 'Save Changes' button under
                  the 'Active Forecasts' tab within the 'Manage Forecasts and
                  Scenario' section.
                </li>
              </ul>
            </li>
            <li>
              Selecting Save Options:
              <ul>
                <li>
                  Under the 'Create New or Overwrite a Current Forecast' field,
                  choose the appropriate option based on your needs:
                  <ul>
                    <li>
                      <b> Create New Forecast: </b>If you want to save the
                      changes as a new forecast, providing a distinct name and
                      description for the updated forecast model.
                    </li>
                    <li>
                      <b> Overwrite Current Forecast:</b> If you prefer to
                      overwrite the existing forecast with the changes made. In
                      this case, the system will retain the original forecast
                      name and description.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Click the 'Save' or 'Save Changes' button to apply the changes to
              the selected forecast.
            </li>
          </ol>
        </SectionContent>
      </SubSectionHeader>
      <SubSectionHeader title='Open a saved forecast' id='open-forecast'>
        <SectionContent>
          <ImageContainer fileName='open-forecast.png' alt='Open forecast' />
          <ol>
            <li>
              Navigate to the Open Forecast Option:
              <ul>
                <li>
                  Click on the 'Open a Saved Forecast' link in the left
                  navigation menu or use the 'Open' button under the 'Saved
                  Forecasts' tab within the 'Manage Forecasts and Scenarios'
                  section.
                </li>
              </ul>
            </li>
            <li>
              Select a Forecast:
              <ul>
                <li>
                  In the 'Open a Saved Forecast' form, choose the forecast you
                  want to open from the available list.
                </li>
              </ul>
            </li>
          </ol>
          Opening a Forecast Not in the Workspace
          <ol>
            <li>
              <b>New Working Instance:</b> If the selected forecast is not in
              the current workspace, a new working instance will be added to the
              workspace upon opening.
            </li>
          </ol>
          Opening a Forecast Already in the Workspace
          <ol>
            <li>
              Re-open Without Saving:
              <ul>
                <li>
                  Choose this option if you want to ignore the current changes
                  in the workspace. The last saved version of the forecast will
                  replace the current version in the workspace.
                </li>
                <li>This operation cannot be reverted, so use it carefully.</li>
              </ul>
            </li>
            <li>
              Save & Open:
              <ul>
                <li>
                  Select this option to save any in-progress changes in the
                  workspace before opening the selected forecast. This ensures
                  that your recent modifications are preserved.
                </li>
              </ul>
            </li>
          </ol>
        </SectionContent>
      </SubSectionHeader>
      <ManageForecastScenarios />
    </React.Fragment>
  );
};
