import React from "react";
import { SectionHeader } from "../../../common/SectionHeader";
import { SectionContent } from "../../../common/SectionContent";
import { SubSectionHeader } from "../../../common/SubSectionHeader";
import { BusinessModel } from "./BusinessModel";
import { Pricing } from "./Pricing";
import { CurrentCustomers } from "./CurrentCustomers";
import { AdvancedOptions } from "./AdvancedOptions";
import { Categories } from "./Categories";
import { ImageContainer } from "../../../common/ImageContainer";

export const ProductAndServices = () => {
  return (
    <React.Fragment>
      <SectionHeader title="PRODUCTS & SERVICES">
        <SectionContent>
          {`Use this section to review/add/edit/delete a product or service to be forecasted. Adding a product or service once will add it in all scenarios.`}
          <br />
          <i>Why we ask this</i>
          {`: Our algorithms are designed to adjust and require as few inputs as possible to generate a model. The information we gather describes product attributes and is an important aspect of our methodology.`}
          <br />
          {`All inputs are provided in the Input assumptions section. Appropriate input rows will be automatically generated in the input spreadsheet depending on your input selections for each product.`}
          &nbsp;Go to{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://app.seqnc.com/app/add-product"
          >
            Add product
          </a>{" "}
          to add a product.
          <ImageContainer
            fileName="producAndServices.png"
            alt="Product & Services"
          />
        </SectionContent>
      </SectionHeader>
      <SubSectionHeader title="Product/service name/ID">
        <SectionContent>
          {`Product ID: Provide a short form name/ID (40 characters or less) for the product/service you are adding. This ID will be used throughout the model to reference this product/service. The Product ID must be unique and may not be repeated for another product/service.`}
          {`Product details: This is an optional field where you can provide additional details for the product/service.`}
        </SectionContent>
      </SubSectionHeader>
      <SubSectionHeader title="Select category">
        <SectionContent>
          {`Category is a way of classifying a product. Choose a category from the dropdown. If you want to add a new category then you can go to product summary table page. On the left side tree right click and in context menu select “Add category”`}
        </SectionContent>
      </SubSectionHeader>
      <BusinessModel />
      <Pricing />
      <CurrentCustomers />
      <AdvancedOptions />
      <Categories />
    </React.Fragment>
  );
};
