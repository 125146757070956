import { Button, DialogActions } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { Link } from "react-router-dom";

export interface SimpleDialogProps {
  title: string;
  message: string;
  open: boolean;
  onClose: Function;
}

export const SimpleDialog = (props: SimpleDialogProps) => {
  const { title, message, onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth='xl'>
      <DialogTitle align='center'>{title}</DialogTitle>
      <Button
        component={Link}
        to='https://app.seqnc.com'
        variant='contained'
        sx={{
          ":hover": {
            color: "white",
          },
        }}
      >
        {message}
      </Button>

      {/* <List sx={{ pt: 0 }}>
        {emails.map((email) => (
          <ListItem disableGutters>
            <ListItemButton
              onClick={() => handleListItemClick(email)}
              key={email}
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={email} />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem disableGutters>
          <ListItemButton
            autoFocus
            onClick={() => handleListItemClick("addAccount")}
          >
            <ListItemAvatar>
              <Avatar>
                <AddIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary='Add account' />
          </ListItemButton>
        </ListItem>
      </List> */}
    </Dialog>
  );
};
