import React from "react";
import { SubSectionHeader } from "../../../common/SubSectionHeader";
import { SectionContent } from "../../../common/SectionContent";
import { ImageContainer } from "../../../common/ImageContainer";

export const CopyLineItems = () => {
  return (
    <React.Fragment>
      <SubSectionHeader title='Copy line items'>
        <SectionContent>
          <p>
            The "Copy Line Items" feature within the Cost & Expenses section
            offers a convenient way to duplicate line items from one category to
            another, streamlining your workflow and saving time.
          </p>
          <p>
            Key Information:
            <ol>
              <li>
                This feature is exclusive to the "Cost & Expenses" section.
              </li>
              <li>
                It simplifies the process of replicating line items across
                different categories.
              </li>
            </ol>
          </p>
          <p>
            How to Use:
            <ol>
              <li>
                Navigate to the "Cost & Expenses" section in your Seqnc finance
                document.
              </li>
              <li>Locate and click on the "Copy Line Items" button.</li>
            </ol>
          </p>
          <ImageContainer fileName='copyLineitems.png' alt='Copy Lineitems' />
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
