import React from "react";
import { SubSectionHeader } from "../../common/SubSectionHeader";
import { SectionContent } from "../../common/SectionContent";
import { SectionHeader } from "../../common/SectionHeader";
import { ImageContainer } from "../../common/ImageContainer";

export const ManageTeam = () => {
  return (
    <React.Fragment>
      <SectionHeader title='MANAGE TEAM'>
        <SectionContent>
          <p>
            To access the 'Manage Team' tab, click on the 'Manage account and
            team' link under the Settings menu. Within this tab, users can
            perform team management tasks, including adding, deleting, and
            updating users.
          </p>
          <p>
            In a team, users are categorized as either owners or team members.
            Each team has only one owner, and only the owner has the authority
            to add team members.
          </p>
          <p>
            The owner, being the team leader, can perform various tasks such as
            adding team members, managing the account, modifying the company
            profile, and accessing the workspace. Owners can access the 'Manage
            Team' tab through the 'Manage Account and Team' link under the
            settings dropdown menu to execute these actions.
          </p>
          <p>
            On the other hand, team members have access solely to the workspace.
            Unlike owners, team members do not have a 'Manage Team' tab in the
            'Manage Account and Team' link under the settings dropdown menu, as
            they lack the ability to make changes to the team itself."
          </p>
        </SectionContent>
      </SectionHeader>
      <SubSectionHeader title='ADDING, EDITING OR DELETING TEAM MEMBERS'>
        <SectionContent>
          {`To add individual team members, follow these steps:`}
          <ol type='1'>
            <li>Select the 'Add Team Member' button.</li>
            <li>
              Enter the email address of the user you wish to add as a new team
              member.
            </li>
          </ol>
          <ImageContainer fileName='manageTeam.png' alt='Manage Team' />{" "}
          <p>
            This process enables you to efficiently expand your team by adding
            individual members through a straightforward and user-friendly
            interface.
          </p>
          <ImageContainer fileName='addTeamMember.png' alt='Add Team Member' />
        </SectionContent>
      </SubSectionHeader>
      <SubSectionHeader title='Suspend/Delete team member'>
        <SectionContent>
          <p>
            Suspending or deleting a team member can be initiated by the owner,
            resulting in restricted access for the affected team member. Please
            note the following distinctions between suspending and deleting a
            team member:
          </p>
          <ol>
            <li>
              Suspending a Team Member:
              <ul>
                <li>
                  Suspending a team member is reversible, allowing the user to
                  be restored to their previous status. To suspend, click on the
                  'Suspend' link corresponding to the team member. To restore a
                  suspended user, the 'Suspend' link transforms into 'Restore'
                  on the suspended user's line. Select 'Restore' to bring the
                  team member back to their original status.
                </li>
              </ul>
            </li>
            <li>
              Deleting a Team Member:
              <ul>
                <li>
                  Deleting a team member is irreversible. To delete, click on
                  the 'Delete' link on the team member's line. When deleting,
                  you'll be prompted to transfer archives and templates to other
                  users or delete them entirely. Choose your preferred option,
                  input your password, and confirm the deletion. Deleted team
                  members can only be restored by adding them as new team
                  members.
                </li>
              </ul>
            </li>
          </ol>
          <p>
            Ensure careful consideration when deciding to suspend or delete a
            team member, as each action has specific implications for access and
            data preservation.
          </p>
          <ImageContainer
            fileName='deleteTeamMember.png'
            alt='Delete Team Member'
          />
        </SectionContent>
      </SubSectionHeader>
      <SubSectionHeader title='Receiving Team Member Invitation'>
        <SectionContent>
          <ol type='1'>
            <li>
              Existing User
              <ul>
                <li>
                  If an owner adds a user as a team member who already has an
                  account with Seqnc, the recipient will receive an email
                  notification, informing them of their addition as a team
                  member. The user can then access the private workspace for
                  that account.
                </li>
              </ul>
            </li>
            <li>
              New User:
              <ul>
                <li>
                  If an owner adds a user as a team member who does not have an
                  existing Seqnc account, the recipient will receive two email
                  notifications. The first is a welcome email prompting the
                  recipient to set a password and create an account. The second
                  email notifies them of their addition as a team member. Once
                  the user sets their password and logs in, they gain access to
                  the private workspace and any shared archives/templates.
                </li>
              </ul>
            </li>
          </ol>
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
