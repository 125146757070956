import React from "react";
import { SectionHeader } from "../../common/SectionHeader";
import { SectionContent } from "../../common/SectionContent";
import { ImageContainer } from "../../common/ImageContainer";

export const Summary = () => {
  return (
    <SectionHeader title='SUMMARY'>
      <SectionContent>
        <b>Sales Assumptions:</b>
        <p>
          This section provides a concise overview of the sales assumptions,
          outlining key forecasts and projections that drive revenue
          expectations. It includes critical factors such as sales growth rates,
          pricing strategies, and market penetration assumptions.
        </p>
        <ImageContainer fileName='summary.png' alt='Summary' />
        <b>Operating budget</b>
        <p>
          The Operating Budget summary offers a comprehensive view of the
          financial plan detailing anticipated expenditures across various
          operational facets. This includes expenses related to production,
          marketing, research and development, and other operational areas
          essential for business functioning.
        </p>
        <b>Headcount by job_role</b>
        <p>
          Get an at-a-glance view of the organizational structure with the
          Headcount by Job Role summary. This report breaks down the
          distribution of employees across different job roles, offering
          insights into the composition of your workforce.
        </p>
        <b>Headcount by department</b>
        <p>
          This report provides a detailed breakdown of personnel distribution
          across various departments, specifically categorized by job roles. It
          is applicable when all job roles are systematically categorized. The
          report offers a granular view of the workforce composition within each
          department, allowing for targeted insights into organizational
          structure and resource allocation. This tailored report is
          particularly useful for organizations with a well-defined job role
          categorization system, enhancing precision in workforce planning and
          management.
        </p>
        <b>P&L summary</b>
        <p>
          The Profit and Loss (P&L) Summary is a condensed snapshot of the
          company's financial performance. It outlines revenues, costs, and
          expenses, providing a quick assessment of profitability. Key metrics
          such as net income, gross margin, and operating expenses are
          highlighted.
        </p>
        <b>Capital required summary</b>
        <p>
          The Capital Required Summary offers a consolidated view of the capital
          needs based on scenario assumptions. It encapsulates key metrics such
          as operating shortfalls, cumulative capital requirements, and
          adjustments for any debt or equity additions during the forecast
          period.
        </p>
      </SectionContent>
    </SectionHeader>
  );
};
