import React from "react";
import { SubSectionHeader } from "../../../common/SubSectionHeader";
import { SectionContent } from "../../../common/SectionContent";
import { ImageContainer } from "../../../common/ImageContainer";
import GoToLink1 from "../../../common/GoToLink1";

export const WorkForce = () => {
  return (
    <React.Fragment>
      <SubSectionHeader title="WORKFORCE PLAN (DIRECT)/(OPEX)">
        <SectionContent>
          <p>
            The 'Direct/Opex Workforce Plan Assumptions' spreadsheet serves as
            the input hub for your hiring plan details. In this spreadsheet, you
            can specify the starting/current headcount and the number of
            individuals you plan to add for each job/role. For each row, input
            the number of additional hires planned for each month.
          </p>
          <GoToLink1
            goToLink="/app/workforce-plan-direct-sheet"
            goToText="Workforce plan(Direct)/(Opex)"
            parentSection="Input assumptions/Input spreadsheet"
          />
          <ImageContainer fileName="workForce.png" alt="Work Force" />{" "}
          <p>
            Additionally, you have the flexibility to define salary increases
            and bonus amounts expressed as a percentage of the base salary. To
            streamline the input process, you only need to specify the increase
            in the month of the planned increment. For example, if a salary
            increase is scheduled for December, you only need to enter the
            percentage of the planned increase for that specific month. The
            model will automatically carry forward the increase to subsequent
            months, simplifying the data entry process.
          </p>
          <ImageContainer fileName="jobRolesInput.png" alt="Work Force" />{" "}
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
