import React from "react";
import { SectionHeader } from "../../../common/SectionHeader";
import { SectionContent } from "../../../common/SectionContent";
import { ImageContainer } from "../../../common/ImageContainer";

export const Collaboration = () => {
  return (
    <React.Fragment>
      <SectionHeader title='Collaboration'>
        <SectionContent>
          <ol>
            <li>
              Facilitates inviting users to collaborate on financial forecasts
              within the workspace.
            </li>
            <li>
              The forecast owner holds the permission to save changes made in
              the workspace.
            </li>
            <li>
              Owners can extend collaboration invitations with either 'Read &
              edit' or 'Read' access levels.
            </li>
            <li>
              The owner retains the ability to revoke collaboration access at
              any time.
            </li>
          </ol>
          <ImageContainer
            fileName='inviteCollaborator.png'
            alt='Cost and Expenses'
          />
        </SectionContent>
      </SectionHeader>
    </React.Fragment>
  );
};
