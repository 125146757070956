import React from "react";
import { RightPanel } from "./RightPanel";

type Props = {
  toggleNavPanel: boolean;
};

export const ContentPanel = (props: Props) => {
  const { toggleNavPanel } = props;

  return (
    <React.Fragment>
      <RightPanel toggleNavPanel={toggleNavPanel} />
    </React.Fragment>
  );
};
