import React from "react";
import { SectionHeader } from "../../common/SectionHeader";
import { SectionContent } from "../../common/SectionContent";
import { ImageContainer } from "../../common/ImageContainer";

export const InputAssumptions = () => {
  return (
    <React.Fragment>
      <SectionHeader title='INPUT ASSUMPTIONS'>
        <SectionContent>
          <p>
            This section of the application allows you to provide assumptions
            for the configured model. You can input your assumptions in two ways
            within the Seqnc application. The first method utilizes an
            integrated spreadsheet, while the second method involves using forms
            with dropdown selectors and input fields.
          </p>
          <ImageContainer
            fileName='inputAssumptions.png'
            alt='Input Assumptions'
          />
        </SectionContent>
      </SectionHeader>
    </React.Fragment>
  );
};
