import React from "react";
import { SubSectionHeader } from "../../common/SubSectionHeader";
import { SectionContent } from "../../common/SectionContent";
import { SectionHeader } from "../../common/SectionHeader";
import { ImageContainer } from "../../common/ImageContainer";

export const ManageAccount = () => {
  return (
    <React.Fragment>
      <SectionHeader title='MANAGE ACCOUNT/COMPANY'>
        <SectionContent>
          Every Seqnc user is associated with a company. This association
          indicates that each user account is linked to a specific company
          profile. The company details include information such as the company
          name, address, and possibly other relevant information. Each company
          is associated with a subscription plan, indicating the level of
          service or features they have access to. Additionally, there may be
          add-ons that can be included in the subscription for additional
          functionalities. Users have the ability to update the details of the
          company they are associated with. This is done through an "Edit
          company profile" feature.
          <ImageContainer
            fileName='companyProfile.png'
            alt='Edit Company Profile'
          />
        </SectionContent>
        <SectionContent>
          You can create multiple companies with your account and can opt for
          separate plan for each company. You can switch between companies
          through Switch/create new account link. Select a company you want to
          work with and click on select to work with the company. Also upon
          login if you have more than one company selection screen will be shown
          to select the company. You can either select a company from the list
          or you can create a new company by clicking Create a new account.
          <ImageContainer
            fileName='switchCompany.png'
            alt='Switch Company'
            style={{ height: "500px" }}
          />
        </SectionContent>
        <SectionContent>
          <p>
            Manage your account seamlessly through the Account Management page.
            Perform a variety of account-related transactions, including:
          </p>
          <ol type='1'>
            <li>
              Renew Subscription:
              <ul>
                <li>
                  Extend the duration of your subscription for uninterrupted
                  access to premium features.
                </li>
              </ul>
            </li>
            <li>
              Cancel Subscription:
              <ul>
                <li>
                  Opt to discontinue your current subscription, if needed.
                </li>
              </ul>
            </li>
            <li>
              Change Plan:
              <ul>
                <li>
                  Modify your subscription plan to better suit your evolving
                  needs.
                </li>
              </ul>
            </li>
            <li>
              Buy Add-On:
              <ul>
                <li>
                  Enhance your account capabilities by purchasing additional
                  add-ons.
                </li>
              </ul>
            </li>
            <li>
              Cancel Add-On:
              <ul>
                <li>
                  Remove any supplementary add-ons that are no longer required.
                </li>
              </ul>
            </li>
            <li>
              Update Billing Details:
              <ul>
                <li>
                  Keep your billing information current by making necessary
                  updates.
                </li>
              </ul>
            </li>
            <li>
              Change Account Owner:
              <ul>
                <li>Transfer ownership of the account, if required.</li>
              </ul>
            </li>
          </ol>
          <p>
            These account management capabilities empower users to tailor their
            account to specific requirements, ensuring a flexible and
            personalized experience.
          </p>
          <ImageContainer fileName='manageAccount.jpg' alt='Manage Account' />
        </SectionContent>
      </SectionHeader>
      <SubSectionHeader title='CHANGE ACCOUNT OWNER'>
        <SectionContent>
          <p>
            To access the 'Change Account Owner' page, click on 'Change Account
            Owner' in the Account Management tab. Follow these steps to change
            the owner of an account:
          </p>
          <ol type='1'>
            <li>
              Navigate to 'Change Account Owner' under Account Management.
            </li>
            <li>Enter your password for verification.</li>
            <li>Provide the email address of the new account owner.</li>
            <li>Enter a one-time password for added security.</li>
          </ol>
          <p>
            Completing these steps will successfully transfer ownership of the
            account to the specified email address, ensuring a smooth transition
            of account control.
          </p>
          <ImageContainer
            fileName='changeAccountOwner.png'
            alt='Change Account Owner'
          />
        </SectionContent>
      </SubSectionHeader>
      <SubSectionHeader title='CREATE A NEW ACCOUNT'>
        <SectionContent>
          <p>{`You can create a new company and attach a subscription plan for it. You can click on Create a new account button to initiate this process. Once you enter the details of the company you will be guided through plan selection and payment screens. Once you complete the payment company will be attached to your account and you can work with the company by selecting it in company selection screen.`}</p>
          <ImageContainer fileName='createCompany.png' alt='Create Company' />
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
