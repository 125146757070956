import { Box, Container, Paper, Typography, createTheme } from "@mui/material";
import React from "react";
import { ThemeProvider } from "react-bootstrap";

const footerTheme = createTheme({
  typography: {
    fontSize: 8,
  },
});

export const Footer = () => {
  return (
    <React.Fragment>
      <ThemeProvider theme={footerTheme}>
        <Paper
          sx={{
            marginTop: "calc(10% + 0px)",
            position: "fixed",
            bottom: 0,
            width: "100%",
          }}
          component='footer'
          square
          variant='outlined'
        >
          <Container maxWidth='lg'>
            <Box
              style={{
                textAlign: "center",
              }}
            >
              <Box>
                <Typography>© 2024 Seqnc Inc. All rights reserved.</Typography>
              </Box>
            </Box>
          </Container>
        </Paper>
      </ThemeProvider>
    </React.Fragment>
  );
};
